import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const EligibilityCriteria = styled(RichTextWithModal)`
  ${RichText} {
    ${mediaQuery.tabletPortrait`
      max-width : 80%;
    `}
    h2 {
      ${fonts.headingSmall};
      margin-bottom: ${spacing(2)};

      ${mediaQuery.tabletPortrait`
        margin-bottom: ${spacing(3)};
      `}

      ${mediaQuery.tabletLandscape`
        margin-bottom: ${spacing(3)};
      `}

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      ${fonts.paragraphLarge}
    }
  }
`;
