import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import quoteInProgressClient from 'apiHelpers/quoteInProgressClient';
import { graphql, navigate } from 'gatsby';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import LoadQuoteInProgressWrapper from 'components/ApiRequestWrapper/LoadQuoteInProgressWrapper';
import HeroBanner from 'components/Header/HeroBanner';
import Layout from 'components/Layout';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import AboutYourPetsForm from 'forms/AboutYourPetsForm';
import { PageTitle, trackRichTextLinkClick } from 'helpers/eventTracking';
import { getQueryParam } from 'helpers/getQueryParam';
import { usePageTracking } from 'helpers/pageTracking';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import useLoadingState from 'helpers/useLoadingState';
import { ReferralCodes, useReferralCodes } from 'state/formData/referralCodes';
import { CsHero } from 'types/contentStack';
import { EligibilityCriteria } from './styles';

type AboutYourPetProps = {
  data: {
    csPetAboutYourPet: {
      meta_title: string;
      hero: CsHero;
      eligibility_criteria: string;
    };
  };
  location: Location;
};

export const query = graphql`
  query {
    csPetAboutYourPet {
      meta_title
      hero {
        heading
        subheading
      }
      eligibility_criteria
    }
  }
`;

const AboutYourPet: React.FC<AboutYourPetProps> = ({
  data: {
    csPetAboutYourPet: { eligibility_criteria, hero, meta_title },
  },
  location,
}) => {
  const dispatch = useDispatch();
  const [getReferralCodes, updateReferralCodes] = useReferralCodes();

  const { withLoadingState } = useLoadingState();
  const pageDoesNotHaveReferralCodes = !getQueryParam(location, 'affinity');

  useEffect(() => {
    const validCodes = [
      ['affinity', getQueryParam(location, 'affinity')],
      ['subAffinity', getQueryParam(location, 'subAffinity')],
    ].filter(([, codeValue]) => !!codeValue);
    const referralCodes: ReferralCodes = Object.fromEntries(validCodes);

    withLoadingState(async () => {
      updateReferralCodes(referralCodes);
      if (!isEmpty(referralCodes)) {
        await quoteInProgressClient.saveQuoteInProgress(referralCodes);
      }
    });
  }, [dispatch, location, updateReferralCodes, withLoadingState]);

  const moveNext = (): void => {
    navigate(quoteAndBuyRoutes.aboutYou);
  };

  const pageSufficientlyLoaded =
    pageDoesNotHaveReferralCodes || !!getReferralCodes.affinity;
  usePageTracking(meta_title, pageSufficientlyLoaded);

  return (
    <LoadQuoteInProgressWrapper location={location}>
      <Layout
        pageTitle={PageTitle.AboutYourPet}
        currentStep={QuoteAndBuyStep.AboutYourPet}
        metaTitle={meta_title}>
        <HeroBanner heading={hero.heading} subheading={hero.subheading} />
        <Grid alignLeft>
          <GridItem desktop={8} tabletLandscape={8}>
            <EligibilityCriteria
              data-cy="eligibility_criteria"
              html={eligibility_criteria}
              onLinkClick={trackRichTextLinkClick(PageTitle.AboutYourPet)}
              pageTitle={PageTitle.AboutYourPet}
            />
          </GridItem>
        </Grid>
        <AboutYourPetsForm moveNext={moveNext} />
      </Layout>
    </LoadQuoteInProgressWrapper>
  );
};

export default AboutYourPet;
