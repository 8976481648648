import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import Image from '@rsa-digital/evo-shared-components/components/Image';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import RichTextWithModal from 'components/RichTextWithModal';

export const StyledPanel = styled.div`
  border: 1px ${colors.neutral04} solid;
  border-radius: ${spacing(1)};
  padding: ${spacing(3)};
  ${mediaQuery.tabletPortrait`
    padding: ${spacing(3)};
  `};
  margin-bottom: ${spacing(2)};
  display: flex;
`;

export const StyledRichText = styled(RichText)`
  ${fonts.paragraphLarge}
  margin-right: auto;
  border: 0;
  margin-bottom: ${spacing(2)};
`;

export const StyledRichTextWithModal = styled(RichTextWithModal)`
  ${fonts.paragraphLarge}
  margin-right: auto;
  border: 0;
  margin-bottom: ${spacing(2)};
`;

export const Subheading = styled.p`
  ${fonts.headingSmall};
`;

export const StyledPanelHeadingContainer = styled.div`
  display: flex;
`;

export const StyledPanelHeading = styled.p`
  ${fonts.headingSmall}
  margin: 0;
`;

export const StyledQuestionField = styled(QuestionField)`
  margin-bottom: 0;
`;
export const SizedImage = styled(Image)`
  flex-shrink: 0;
  width: ${spacing(3)};
  max-height: ${spacing(4)};
  margin-right: ${spacing(2)};
  margin-left: ${spacing(2)};
  margin-bottom: ${spacing(1)};
`;

export const StyledFlexGrid = styled(Grid)`
  display: flex;
  margin-bottom: ${spacing(2)};
  margin-top: ${spacing(2)};
`;
