import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BreedPlaybackPanel from 'components/BreedPlaybackPanel';
import CustomModal from 'components/CustomModal';
import QuestionField from 'components/QuestionField';
import useRichTextWithModal from 'components/RichTextWithModal/useRichTextwithModal';
import TypeaheadInput from 'components/TypeaheadInput';
import { DesignConstancts } from 'helpers/businessConstants';
import {
  PageTitle,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackRadioButtonClick,
  trackRichTextLinkClick,
} from 'helpers/eventTracking';
import getPetIdForInput from 'helpers/getPetId';
import { INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE } from 'helpers/inputRegexes';
import {
  dogBreedType_CROSS_BREED,
  dogBreedType_MONGREL,
  dogBreedType_PEDIGREE,
  petType_DOG,
} from 'helpers/referenceDataConstants';
import { Pet, PetsDetails, PetWithKey } from 'state/formData/petsDetails';
import { CsErrorsNotFoundOnly } from 'types/contentStack';
import { ReferenceDataOption } from 'types/referenceData';
import { StyledRadioInput } from './styles';
import { useDogBreedTypeOptions } from '../../useDogBreedTypeOptions';
import { useMongrelSizeOptions } from '../../useMongrelSizeOptions';
import useAboutYourPetQuestions from '../questions';

type CsAboutYourPetDogErrorMessages = {
  csPetAboutYourPetDogQuestions: {
    pedigree_dog_breed_name: CsErrorsNotFoundOnly;
    cross_breed_dog_breed_name: CsErrorsNotFoundOnly;
  };
};

const query = graphql`
  query {
    csPetAboutYourPetDogQuestions {
      pedigree_dog_breed_name {
        error_messages {
          not_found
        }
      }
      cross_breed_dog_breed_name {
        error_messages {
          not_found
        }
      }
    }
  }
`;

type DogBreedQuestionsProps = {
  petDetails: Pet;
  updatePetDetails: (update: Partial<PetWithKey>) => void;
  index: number;
  formValidation: {
    getError: FieldFunction<{ petsDetails: PetsDetails }, string | undefined>;
    showValidation: FieldFunction<{ petsDetails: PetsDetails }, void>;
  };
  dogBreedReferenceData: ReferenceDataOption[];
  pageTitle: PageTitle;
};

const DogBreedQuestions: React.FC<DogBreedQuestionsProps> = ({
  petDetails,
  updatePetDetails,
  index,
  formValidation: { getError, showValidation },
  dogBreedReferenceData,
  pageTitle,
}) => {
  const errorMessages = useStaticQuery<CsAboutYourPetDogErrorMessages>(query);

  const questions = useAboutYourPetQuestions(petDetails);

  const getIdForInput = getPetIdForInput(index);
  const getAnalyticsDescriptionForInput = (input: string): string =>
    `Pet ${index} - ${input}`;

  const dogBreedTypeOptions = useDogBreedTypeOptions();
  const mongrelSizeOptions = useMongrelSizeOptions();

  const showDogQuestions = petDetails.petType === petType_DOG;

  const showPedigreeDogBreedNameQuestion =
    showDogQuestions && petDetails.dogBreedType === dogBreedType_PEDIGREE;
  const showCrossBreedDogBreedNameQuestion =
    showDogQuestions && petDetails.dogBreedType === dogBreedType_CROSS_BREED;
  const showMongrelSizeQuestion =
    showDogQuestions && petDetails.dogBreedType === dogBreedType_MONGREL;

  const dogBreedNameMappedOptions = dogBreedReferenceData.map((option) => ({
    id: option.value,
    label: option.name,
  }));

  const nonMongrelBreedNameQuestion = showPedigreeDogBreedNameQuestion
    ? questions.pedigreeDogBreedName
    : questions.crossBreedDogBreedName;

  const nonMongrelBreedNameAnalyticsDescription = showPedigreeDogBreedNameQuestion
    ? 'Pedigree dog breed name'
    : 'Cross breed dog breed name';

  const showBreedInfoPanel = showMongrelSizeQuestion
    ? !!petDetails.mongrelSize
    : !!petDetails.dogBreedName;
  const menuMaxHeight = DesignConstancts.PET_BREED_DROPDOWN_MENU_MAX_HEIGHT;
  /* This question field will be rendered on two separate occasions:
  When a user selects 'pedigree' and when a user selects 'cross breed'
  Rendering the question field each time will clear the field when changing breed type as is required,
  as well as animating the field to make it clear the user needs to fill it in again' */
  const nonMongrelBreedNameQuestionField = (noMatchesText: string): React.ReactNode => {
    return (
      <QuestionField
        question={nonMongrelBreedNameQuestion}
        errorText={getError('petsDetails', ['dogBreedName', index])}
        maskQuestionData
        initiallyShowTooltip>
        <TypeaheadInput
          id={getIdForInput('dogBreedName')}
          value={dogBreedNameMappedOptions.find(
            (option) => option.id === petDetails.dogBreedName
          )}
          options={dogBreedNameMappedOptions ?? []}
          placeholder={nonMongrelBreedNameQuestion.placeholder}
          menuMaxHeight={menuMaxHeight}
          noMatchesText={noMatchesText}
          onChange={(option) => {
            updatePetDetails({
              dogBreedName: option?.id,
            });
            trackFormDropdownSelect(
              getAnalyticsDescriptionForInput(nonMongrelBreedNameAnalyticsDescription),
              option?.label ?? 'none'
            );
          }}
          onBlur={() => showValidation('petsDetails', ['dogBreedName', index])}
          onFocus={trackFormDropdownFocus(
            getAnalyticsDescriptionForInput(nonMongrelBreedNameAnalyticsDescription),
            pageTitle
          )}
          onKeyPress={(e) => {
            if (!e.key.match(INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE)) {
              e.preventDefault();
            }
          }}
        />
      </QuestionField>
    );
  };

  const { modalId, setModalId, handleModalButtonClick } = useRichTextWithModal();
  return (
    <>
      <CustomModal
        modalId={modalId}
        onClose={() => {
          setModalId(null);
        }}
        pageTitle={pageTitle}
        trackRichTextLinkClick={trackRichTextLinkClick(pageTitle)}
      />
      <QuestionField
        question={questions.dogBreedType}
        errorText={getError('petsDetails', ['dogBreedType', index])}
        onLinkClick={handleModalButtonClick}
        initiallyShowTooltip
        data-cy="dogBreedTypeField"
        maskQuestionData>
        <RadioInput
          id={getIdForInput('dogBreedType')}
          value={petDetails.dogBreedType}
          options={dogBreedTypeOptions}
          onChange={(e) => {
            updatePetDetails({
              dogBreedType: e.target.value,
              dogBreedName: '',
            });
            trackRadioButtonClick(
              getAnalyticsDescriptionForInput('Dog breed type'),
              e.target.value
            );
          }}
        />
      </QuestionField>
      {showPedigreeDogBreedNameQuestion &&
        nonMongrelBreedNameQuestionField(
          errorMessages.csPetAboutYourPetDogQuestions.pedigree_dog_breed_name
            .error_messages.not_found
        )}
      {showCrossBreedDogBreedNameQuestion &&
        nonMongrelBreedNameQuestionField(
          errorMessages.csPetAboutYourPetDogQuestions.cross_breed_dog_breed_name
            .error_messages.not_found
        )}
      {showMongrelSizeQuestion && (
        <QuestionField
          question={questions.mongrelSize}
          errorText={getError('petsDetails', ['mongrelSize', index])}
          maskQuestionData>
          <StyledRadioInput
            id={getIdForInput('mongrelSize')}
            value={petDetails.mongrelSize}
            options={mongrelSizeOptions}
            onChange={(e) => {
              updatePetDetails({
                mongrelSize: e.target.value,
              });
              trackRadioButtonClick(
                getAnalyticsDescriptionForInput('Mongrel size'),
                e.target.value
              );
            }}
          />
        </QuestionField>
      )}
      {showBreedInfoPanel && (
        <BreedPlaybackPanel pageTitle={PageTitle.AboutYourPet} petDetails={petDetails} />
      )}
    </>
  );
};

export default DogBreedQuestions;
