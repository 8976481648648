import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import {
  RadioItem,
  RadioTextWrapper,
} from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import { IconWrapper } from '@rsa-digital/evo-shared-components/components/Icon/styles';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledRadioInput = styled(RadioInput)`
  && {
    ${RadioItem} {
      gap: ${spacing(3)};
      align-items: center;

      ${IconWrapper} {
        flex-shrink: 0;
      }

      ${RadioTextWrapper} {
        margin: 0;
        padding-right: ${spacing(3)};
        font-size: 1.125rem;
        font-weight: 400;

        p {
          font-weight: 300;
        }

        ${mediaQuery.tabletLandscape`
          font-size: 1rem; 
        `}
      }

      ${mediaQuery.tabletLandscape`
        padding: ${spacing(3)} ${spacing(9)};
      `}
    }
  }
`;
