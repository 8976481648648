import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { InputComponent } from '@rsa-digital/evo-shared-components/components/Form/inputTypes';
import React from 'react';
import { notKnownValue, noValue, yesValue } from 'helpers/booleanWithUnknownHelper';
import { trackRadioButtonClick } from 'helpers/eventTracking';
import useBooleanAnswerText from 'helpers/useBooleanAnswerText';

type BooleanRadioWithUnknownInputProps = {
  id: string;
  value: string;
  onChange: (newValue: string) => void;
  'aria-invalid'?: boolean;
  'aria-describedby'?: string;
  analyticsDescription?: string;
};

const BooleanRadioWithUnknownInput: InputComponent<BooleanRadioWithUnknownInputProps> = ({
  id,
  value,
  onChange,
  'aria-invalid': ariaInvalid,
  'aria-describedby': ariaDescribedBy,
  analyticsDescription,
}) => {
  const { yes, no, not_known } = useBooleanAnswerText();
  return (
    <RadioInput
      id={id}
      options={[
        { name: yes, value: yesValue },
        { name: no, value: noValue },
        { name: not_known, value: notKnownValue },
      ]}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
        trackRadioButtonClick(analyticsDescription || id, e.target.value);
      }}
      aria-describedby={ariaDescribedBy}
      aria-invalid={ariaInvalid}
    />
  );
};

BooleanRadioWithUnknownInput.isFieldSet = true;

export default BooleanRadioWithUnknownInput;
