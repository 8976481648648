import RepeatableSection from '@rsa-digital/evo-shared-components/components/Form/RepeatableSection';
import { Legend } from '@rsa-digital/evo-shared-components/components/Form/RepeatableSection/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const RepeatableSectionWithMargin = styled(RepeatableSection)`
  margin-top: ${spacing(6)};

  ${Legend} {
    ${fonts.headingMedium}
    padding-top: 0;
    margin: ${spacing(3)} 0;

    ${mediaQuery.tabletLandscape`    
      margin-top: ${spacing(4)};
    `};
  }
`;
