import { ComponentProps } from '@rsa-digital/evo-shared-components/helpers/componentProps';
import useValidationWithWarnings from '@rsa-digital/evo-shared-components/helpers/forms/useValidationWithWarnings';
import { AssumptionId } from 'businessLogic/aggregatorAssumptions';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import FormFooter from 'components/FormFooter';
import { addItem, removeItem, updateItem } from 'helpers/arrayHelper';
import { PageTitle, trackFieldError, trackTextButtonClick } from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { initialPet, PetsDetails, usePetsDetails } from 'state/formData/petsDetails';
import AboutYourPetForm from './AboutYourPet';
import useAboutYourPetRules from './AboutYourPet/validation';
import { RepeatableSectionWithMargin } from './styles';
import { usePetDetailsOptions } from './usePetsDetailsOptions';

type AboutYourPetsFormProps = {
  moveNext: () => void;
};

type AboutYourPetFormData = {
  csPetAboutYourPet: {
    next_button_text: string;
  };
};

export const query = graphql`
  query {
    csPetAboutYourPet {
      next_button_text
    }
  }
`;

const AboutYourPetsForm: React.FC<AboutYourPetsFormProps> = ({ moveNext }) => {
  const {
    csPetAboutYourPet: { next_button_text },
  } = useStaticQuery<AboutYourPetFormData>(query);

  const [petsDetails, updatePetsDetails] = usePetsDetails();
  const petRules = useAboutYourPetRules();

  // We hide the Add Another Pet button if there is any pet for which we are not showing all the questions.
  const hideAddAnotherPet = petsDetails.some(
    (pet) => !pet.petName || !pet.petType || !pet.petIsEligible || !pet.petInGoodHealth
  );

  const formErrorRules = {
    ...petRules.errors,
  };

  const formWarningRules = {
    ...petRules.warnings,
  };

  const formDetails = {
    petsDetails,
  };

  const {
    getError,
    getWarning,
    showValidation,
    validateOnSubmit,
  } = useValidationWithWarnings<{ petsDetails: PetsDetails }>(
    formDetails,
    formErrorRules,
    formWarningRules,
    trackFieldError
  );

  const {
    defaultSectionHeadings,
    removePetButtonTexts,
    addPetButtonText,
    petSectionHeading,
  } = usePetDetailsOptions();

  const generateSectionLabel = (index: number): string => {
    const { petName } = petsDetails[index];
    return petName
      ? petNameReplacer(petName, petSectionHeading)
      : defaultSectionHeadings[index];
  };

  const assumptions = useAssumptions();
  const [assumptionsAgreement] = useAssumptionsAgreement();

  const assumptionIdToFormFieldValidationMapping: Partial<Record<
    AssumptionId,
    () => void
  >> = {
    good_health: () => {
      petsDetails.forEach((_, i) => {
        showValidation('petsDetails', ['petInGoodHealth', i]);
      });
    },
    no_complaints_about_behaviour: () => {
      petsDetails.forEach((_, i) => {
        showValidation('petsDetails', ['petIsEligible', i]);
      });
    },
    not_involved_in_legal_action: () => {
      petsDetails.forEach((_, i) => {
        showValidation('petsDetails', ['petIsEligible', i]);
      });
    },
  };

  useEffect(() => {
    if (assumptions !== undefined && !assumptionsAgreement.assumptionsAgreed) {
      assumptions.assumptions.forEach(({ id }) =>
        assumptionIdToFormFieldValidationMapping[id]?.()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      <RepeatableSectionWithMargin
        onAdd={() => {
          trackTextButtonClick(PageTitle.AboutYourPet, addPetButtonText);
          updatePetsDetails(addItem(petsDetails, initialPet));
        }}
        onRemove={(index) => {
          trackTextButtonClick(PageTitle.AboutYourPet, removePetButtonTexts[index]);
          const currentPets = petsDetails;
          const updatedPets = removeItem(currentPets, index);
          updatePetsDetails(updatedPets);
        }}
        disableAdd={petsDetails.length >= 3 || hideAddAnotherPet}
        label={generateSectionLabel}
        addText={addPetButtonText}
        removeText={(index) => removePetButtonTexts[index]}
        labelComponentProps={
          {
            'data-pii-mask': true,
          } as ComponentProps
        }
        alignLeft>
        {petsDetails.map((pet, index) => (
          <AboutYourPetForm
            key={pet.key}
            index={index}
            petDetails={pet}
            updatePetDetails={(update) =>
              updatePetsDetails(updateItem(petsDetails, index, update))
            }
            formValidation={{
              getError,
              getWarning,
              showValidation,
            }}
          />
        ))}
      </RepeatableSectionWithMargin>
      <FormFooter
        contentColumns={{ desktop: 6, tabletLandscape: 6 }}
        moveNextButton={{
          text: next_button_text,
          onClick: () => trackTextButtonClick(PageTitle.AboutYourPet, 'Submit details'),
        }}
        pageTitle={PageTitle.AboutYourPet}
      />
    </form>
  );
};

export default AboutYourPetsForm;
