import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import React from 'react';
import QuestionField from 'components/QuestionField';
import TypeaheadInput from 'components/TypeaheadInput';
import { DesignConstancts } from 'helpers/businessConstants';
import {
  PageTitle,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackRadioButtonClick,
} from 'helpers/eventTracking';
import getPetIdForInput from 'helpers/getPetId';
import { INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE } from 'helpers/inputRegexes';
import {
  catBreedType_NON_PEDIGREE,
  catBreedType_PEDIGREE,
  petType_CAT,
} from 'helpers/referenceDataConstants';
import { Pet, PetsDetails, PetWithKey } from 'state/formData/petsDetails';
import { ReferenceDataOption } from 'types/referenceData';
import { useCatBreedTypeOptions } from '../../useCatBreedTypeOptions';
import useAboutYourPetQuestions from '../questions';

type CatBreedQuestionsProps = {
  petDetails: Pet;
  updatePetDetails: (update: Partial<PetWithKey>) => void;
  index: number;
  formValidation: {
    getError: FieldFunction<{ petsDetails: PetsDetails }, string | undefined>;
    showValidation: FieldFunction<{ petsDetails: PetsDetails }, void>;
  };
  catBreedReferenceData: ReferenceDataOption[];
};

const CatBreedQuestions: React.FC<CatBreedQuestionsProps> = ({
  petDetails,
  updatePetDetails,
  index,
  formValidation: { getError, showValidation },
  catBreedReferenceData,
}) => {
  const questions = useAboutYourPetQuestions(petDetails);

  const getIdForInput = getPetIdForInput(index);
  const getAnalyticsDescriptionForInput = (input: string): string =>
    `Pet ${index} - ${input}`;

  const catBreedTypeOptions = useCatBreedTypeOptions();

  const showCatQuestions = petDetails.petType === petType_CAT;
  const showPedigreeCatBreedNameQuestion =
    showCatQuestions && petDetails.catBreedType === catBreedType_PEDIGREE;
  const showNonPedigreeCatBreedNameQuestion =
    showCatQuestions && petDetails.catBreedType === catBreedType_NON_PEDIGREE;

  const breedNameQuestion = showPedigreeCatBreedNameQuestion
    ? questions.pedigreeCatBreedName
    : questions.nonPedigreeCatBreedName;

  const breedNameAnalyticsDescription = showPedigreeCatBreedNameQuestion
    ? 'Pedigree cat breed name'
    : 'Non pedigree cat breed name';

  const catBreedNameMappedOptions = catBreedReferenceData.map((option) => ({
    id: option.value,
    label: option.name,
  }));

  /* Filtering moggy and moggie out of the list of pedigree breeds to prevent a user selecting a 
   pedigree moggy */
  const catBreedPedigreeMappedOptions = catBreedNameMappedOptions.filter(
    (breed) => breed.label !== 'Moggy' && breed.label !== 'Moggie'
  );
  const menuMaxHeight = DesignConstancts.PET_BREED_DROPDOWN_MENU_MAX_HEIGHT;

  /* This question field will be rendered on two separate occasions:
  When a user selects 'pedigree' and when a user selects 'non-pedigree'
  Rendering the question field each time will clear the field when changing breed type as is required,
  as well as animating the field to make it clear the user needs to fill it in again' */
  const breedNameQuestionField = (
    <QuestionField
      question={breedNameQuestion}
      errorText={getError('petsDetails', ['catBreedName', index])}
      maskQuestionData>
      <TypeaheadInput
        id={getIdForInput('catBreedName')}
        value={catBreedNameMappedOptions.find(
          (option) => option.id === petDetails.catBreedName
        )}
        options={
          (petDetails.catBreedType === catBreedType_PEDIGREE
            ? catBreedPedigreeMappedOptions
            : catBreedNameMappedOptions) ?? []
        }
        placeholder={breedNameQuestion.placeholder}
        menuMaxHeight={menuMaxHeight}
        onChange={(option) => {
          updatePetDetails({
            catBreedName: option?.id,
          });
          trackFormDropdownSelect(
            getAnalyticsDescriptionForInput(breedNameAnalyticsDescription),
            option?.label ?? 'none'
          );
        }}
        onBlur={() => showValidation('petsDetails', ['catBreedName', index])}
        onFocus={trackFormDropdownFocus(
          getAnalyticsDescriptionForInput(breedNameAnalyticsDescription),
          PageTitle.AboutYourPet
        )}
        onKeyPress={(e) => {
          if (!e.key.match(INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE)) {
            e.preventDefault();
          }
        }}
      />
    </QuestionField>
  );

  return (
    <>
      <QuestionField
        question={questions.catBreedType}
        errorText={getError('petsDetails', ['catBreedType', index])}
        maskQuestionData
        initiallyShowTooltip
        data-cy="catBreedTypeField">
        <RadioInput
          id={getIdForInput('catBreedType')}
          value={petDetails.catBreedType}
          options={catBreedTypeOptions}
          onChange={(e) => {
            updatePetDetails({
              catBreedType: e.target.value,
              catBreedName: '',
            });
            trackRadioButtonClick(
              getAnalyticsDescriptionForInput('Cat breed type'),
              e.target.value
            );
          }}
        />
      </QuestionField>
      {showPedigreeCatBreedNameQuestion && breedNameQuestionField}
      {showNonPedigreeCatBreedNameQuestion && breedNameQuestionField}
    </>
  );
};

export default CatBreedQuestions;
