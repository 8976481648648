import { graphql, useStaticQuery } from 'gatsby';

type BooleanAnswerText = {
  csPetGlobalConfig: {
    boolean_answer_text: {
      yes: string;
      no: string;
      not_known: string;
    };
    quote_kick_out_message: string;
  };
};

const booleanAnswerTextQuery = graphql`
  query {
    csPetGlobalConfig {
      boolean_answer_text {
        yes
        no
        not_known
      }
      quote_kick_out_message
    }
  }
`;

const useBooleanAnswerText = (): { yes: string; no: string; not_known: string } => {
  return useStaticQuery<BooleanAnswerText>(booleanAnswerTextQuery).csPetGlobalConfig
    .boolean_answer_text;
};

export default useBooleanAnswerText;
