import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { addLeadingZerosToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import {
  getYoungestPetAgeThresholdDate,
  useFirstEligibleStartDateFromToday,
} from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import BooleanRadioWithUnknownInput from 'components/BooleanWithUnknownRadioInput';
import CostInput from 'components/CostInput';
import { NameInput } from 'components/NameInput/styles';
import QuestionField from 'components/QuestionField';
import RichTextWithModal from 'components/RichTextWithModal';
import { processImageAsset } from 'helpers/csTypeProcessors';
import {
  PageTitle,
  trackFormTextFieldFocus,
  trackRadioButtonClick,
  trackRichTextLinkClick,
} from 'helpers/eventTracking';
import getPetIdForInput from 'helpers/getPetId';
import {
  INPUT_REGEX_PET_NAME,
  INPUT_REGEX_PET_NAME_SPECIAL_CHARS,
} from 'helpers/inputRegexes';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { capitaliseCharacterAfterHyphenAndSpace } from 'helpers/stringHelpers';
import { useTouchAssumption } from 'state/formData/assumptionsAgreement';
import { initialPet, Pet, PetsDetails, PetWithKey } from 'state/formData/petsDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import { CsAsset } from 'types/contentStack';
import CatBreedQuestions from './CatBreedQuestions';
import DogBreedQuestions from './DogBreedQuestions';
import useAboutYourPetQuestions from './questions';
import {
  SizedImage,
  StyledFlexGrid,
  StyledPanel,
  StyledPanelHeading,
  StyledPanelHeadingContainer,
  StyledQuestionField,
  StyledRichTextWithModal,
} from './styles';
import { useCatEligibilityStatement } from '../useCatEligibilityStatement';
import { useDogEligibilityStatement } from '../useDogEligibilityStatement';

type AboutYourPetFormProps = {
  petDetails: Pet;
  updatePetDetails: (update: Partial<PetWithKey>) => void;
  index: number;
  formValidation: {
    getError: FieldFunction<{ petsDetails: PetsDetails }, string | undefined>;
    getWarning: FieldFunction<{ petsDetails: PetsDetails }, string | undefined>;
    showValidation: FieldFunction<{ petsDetails: PetsDetails }, void>;
  };
};

type AboutYourPetFormData = {
  csPetAboutYourPet: {
    important_information: string;
    important_info_header: {
      additional_pets_icon: CsAsset | null;
      heading: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAboutYourPet {
      important_info_header {
        additional_pets_icon {
          ...CsAsset
        }
        heading
      }
      important_information
    }
  }
`;

const AboutYourPetForm: React.FC<AboutYourPetFormProps> = ({
  petDetails,
  updatePetDetails,
  index,
  formValidation: { getError, getWarning, showValidation },
}) => {
  const {
    csPetAboutYourPet: { important_information, important_info_header },
  } = useStaticQuery<AboutYourPetFormData>(query);
  const additionalPetsIcon = processImageAsset(
    important_info_header.additional_pets_icon
  );
  const questions = useAboutYourPetQuestions(petDetails);

  const catEligibilityStatement = useCatEligibilityStatement();
  const dogEligibilityStatement = useDogEligibilityStatement();

  const [policyDetails, updatePolicyDetails] = usePolicyDetails();
  const touchAssumption = useTouchAssumption();

  const getIdForInput = getPetIdForInput(index);
  const getAnalyticsDescriptionForInput = (input: string): string =>
    `Pet ${index} - ${input}`;

  const gendersRefData = useReferenceData('genders');
  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];

  const petIsDog = petDetails.petType === petType_DOG;
  const petIsCat = petDetails.petType === petType_CAT;
  const petNameEntered = !!petDetails.petName;

  // petIsEligible has different meanings for dogs and cats
  // - for dogs: the user has answered YES to one question confirming 3 eligibility statements (legal action, no complaint, kept at home).
  // - for cats: the user has answered YES to the pet is healthy question and pet is kept at home question
  const { petIsEligible } = petDetails;

  const showDogEligibilityStatements = petIsDog && petNameEntered;
  const showCatEligibilityStatements = petIsCat && petNameEntered;

  // const showLivesWithYouQuestion = petIsCat && petNameEntered;

  const showPetInGoodHealthQuestion =
    (petIsDog && petNameEntered && petIsEligible) ||
    (petIsCat && petNameEntered && petIsEligible);

  const showRemainingDogQuestions =
    petIsDog && showPetInGoodHealthQuestion && petDetails.petInGoodHealth;

  const showRemainingCatQuestions =
    petIsCat && petNameEntered && petIsEligible && petDetails.petInGoodHealth;

  const [firstEligibleStartDate] = useFirstEligibleStartDateFromToday();

  const updateCoverStartDate = (pets: Pet[]): Date => {
    const thresholdDate = getYoungestPetAgeThresholdDate(pets);
    if (thresholdDate && thresholdDate > new Date(policyDetails.coverStartDate)) {
      return firstEligibleStartDate;
    }
    return new Date(policyDetails.coverStartDate);
  };

  return (
    <div id={`petsDetails[${index}]`}>
      <QuestionField
        question={questions.petName}
        errorText={getError('petsDetails', ['petName', index])}
        maskQuestionData>
        <NameInput
          id={getIdForInput('petName')}
          value={petDetails.petName}
          placeholder={questions.petName.placeholder}
          onChange={(e) => {
            if (
              !e.target.value.endsWith('  ') &&
              (e.target.value.match(INPUT_REGEX_PET_NAME) ||
                // Users from aggs could have special characters in their pet's name.
                // The below allows them to edit their name if required, while restricting them only to the aggs allowed special characters.
                (!petDetails.petName.match(INPUT_REGEX_PET_NAME) &&
                  e.target.value.match(INPUT_REGEX_PET_NAME_SPECIAL_CHARS)))
            ) {
              updatePetDetails({
                petName: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={() => showValidation('petsDetails', ['petName', index])}
          onFocus={trackFormTextFieldFocus(getAnalyticsDescriptionForInput('Pet name'))}
          maxLength={45}
        />
      </QuestionField>
      <QuestionField
        question={questions.petType}
        errorText={getError('petsDetails', ['petType', index])}
        maskQuestionData>
        <RadioInput
          id={getIdForInput('petType')}
          value={petDetails.petType}
          options={[
            { name: 'Dog', value: petType_DOG },
            { name: 'Cat', value: petType_CAT },
          ]}
          onChange={(e) => {
            const { petName } = petDetails;
            // We do this so that if generic pet questions are answered before petType is selected
            // the inputs are unchanged, but if the petType is changed after initial selection all
            // inputs below the petType question are cleared.
            const petDetailsToUpdate =
              petDetails.petType === ''
                ? {
                    petType: e.target.value,
                  }
                : {
                    ...initialPet,
                    petType: e.target.value,
                    petName,
                  };
            updatePetDetails(petDetailsToUpdate);
            trackRadioButtonClick(
              getAnalyticsDescriptionForInput('Pet type'),
              e.target.value
            );
          }}
        />
      </QuestionField>
      {showCatEligibilityStatements && (
        <FieldGrid alignLeft>
          <StyledPanel>
            <Grid>
              <GridItem>
                <StyledPanelHeadingContainer>
                  <StyledPanelHeading>
                    {petNameReplacer(petDetails.petName, catEligibilityStatement.heading)}
                  </StyledPanelHeading>
                </StyledPanelHeadingContainer>
              </GridItem>
              <GridItem>
                <StyledRichTextWithModal
                  pageTitle={PageTitle.AboutYourPet}
                  html={petNameReplacer(
                    petDetails.petName,
                    catEligibilityStatement.statement_list
                  )}
                  customBulletIcon={catEligibilityStatement.customBulletIcon}
                />
              </GridItem>
              <GridItem>
                <StyledQuestionField
                  question={questions.catEligibilityQuestion}
                  errorText={getError('petsDetails', ['petIsEligible', index])}
                  warningText={getWarning('petsDetails', ['petIsEligible', index])}>
                  <BooleanRadioInput
                    buttonsPerRow={{ mobile: 1 }}
                    id={getIdForInput('petIsEligible')}
                    analyticsDescription={getAnalyticsDescriptionForInput(
                      'Agree with all statements about pet'
                    )}
                    value={petDetails.petIsEligible}
                    onChange={(value) => {
                      updatePetDetails(
                        value
                          ? {
                              petIsEligible: true,
                              petLivesWithYou: true,
                            }
                          : {
                              petIsEligible: false,
                              petLivesWithYou: undefined,
                            }
                      );
                      showValidation('petsDetails', ['petIsEligible', index]);
                    }}
                  />
                </StyledQuestionField>
              </GridItem>
            </Grid>
          </StyledPanel>
        </FieldGrid>
      )}
      {showDogEligibilityStatements && (
        <FieldGrid alignLeft>
          <StyledPanel>
            <Grid>
              <GridItem>
                <StyledPanelHeadingContainer>
                  <StyledPanelHeading>
                    {petNameReplacer(petDetails.petName, dogEligibilityStatement.heading)}
                  </StyledPanelHeading>
                </StyledPanelHeadingContainer>
              </GridItem>
              <GridItem>
                <StyledRichTextWithModal
                  pageTitle={PageTitle.AboutYourPet}
                  html={petNameReplacer(
                    petDetails.petName,
                    dogEligibilityStatement.statement_list
                  )}
                  customBulletIcon={dogEligibilityStatement.customBulletIcon}
                />
              </GridItem>
              <GridItem>
                <StyledQuestionField
                  question={questions.dogEligibilityQuestion}
                  errorText={getError('petsDetails', ['petIsEligible', index])}
                  warningText={getWarning('petsDetails', ['petIsEligible', index])}>
                  <BooleanRadioInput
                    buttonsPerRow={{ mobile: 1 }}
                    id={getIdForInput('petIsEligible')}
                    analyticsDescription={getAnalyticsDescriptionForInput(
                      'Agree with all statements about pet'
                    )}
                    value={petDetails.petIsEligible}
                    onChange={(value) => {
                      touchAssumption([
                        'not_involved_in_legal_action',
                        'no_complaints_about_behaviour',
                      ]);
                      updatePetDetails(
                        value
                          ? {
                              petIsEligible: true,
                              dogLegalAction: false,
                              dogComplaints: false,
                              petLivesWithYou: true,
                            }
                          : {
                              petIsEligible: false,
                              dogLegalAction: undefined,
                              dogComplaints: undefined,
                              petLivesWithYou: undefined,
                            }
                      );
                      showValidation('petsDetails', ['petIsEligible', index]);
                    }}
                  />
                </StyledQuestionField>
              </GridItem>
            </Grid>
          </StyledPanel>
        </FieldGrid>
      )}
      {showPetInGoodHealthQuestion && (
        <QuestionField
          question={questions.petInGoodHealth}
          errorText={getError('petsDetails', ['petInGoodHealth', index])}
          warningText={getWarning('petsDetails', ['petInGoodHealth', index])}
          maskQuestionData>
          <BooleanRadioInput
            id={getIdForInput('petInGoodHealth')}
            analyticsDescription={getAnalyticsDescriptionForInput(
              'Pet is in good health'
            )}
            value={petDetails.petInGoodHealth}
            onChange={(value) => {
              touchAssumption('good_health');
              updatePetDetails({
                petInGoodHealth: value,
              });
              showValidation('petsDetails', ['petInGoodHealth', index]);
            }}
          />
        </QuestionField>
      )}
      {showRemainingDogQuestions && (
        <DogBreedQuestions
          index={index}
          petDetails={petDetails}
          updatePetDetails={updatePetDetails}
          formValidation={{ getError, showValidation }}
          dogBreedReferenceData={dogBreedsRefData}
          pageTitle={PageTitle.AboutYourPet}
        />
      )}
      {showRemainingCatQuestions && (
        <CatBreedQuestions
          index={index}
          petDetails={petDetails}
          updatePetDetails={updatePetDetails}
          formValidation={{ getError, showValidation }}
          catBreedReferenceData={catBreedsRefData}
        />
      )}
      {(showRemainingDogQuestions || showRemainingCatQuestions) && (
        <>
          <QuestionField
            question={questions.petGender}
            errorText={getError('petsDetails', ['petGender', index])}
            maskQuestionData>
            <RadioInput
              id={getIdForInput('petGender')}
              value={petDetails.petGender}
              options={gendersRefData?.genders ?? []}
              onChange={(e) => {
                updatePetDetails({
                  petGender: e.target.value,
                });
                trackRadioButtonClick(
                  getAnalyticsDescriptionForInput('Pet gender'),
                  e.target.value
                );
              }}
            />
          </QuestionField>
          <QuestionField
            question={questions.petDob}
            errorText={getError('petsDetails', ['petDob', index])}
            warningText={getWarning('petsDetails', ['petDob', index])}
            maskQuestionData>
            <DateInput
              data-cy="pet-dob"
              id={getIdForInput('petDob')}
              value={petDetails.petDob}
              onChange={(value) => updatePetDetails({ petDob: value })}
              onBlur={() => {
                updatePetDetails({
                  petDob: addLeadingZerosToDateValue(petDetails.petDob),
                });
                updatePolicyDetails({
                  coverStartDate: updateCoverStartDate([petDetails]).toISOString(),
                });
                showValidation('petsDetails', ['petDob', index]);
              }}
              onFocus={trackFormTextFieldFocus(
                getAnalyticsDescriptionForInput('Pet dob')
              )}
            />
          </QuestionField>
          <QuestionField
            question={questions.petCost}
            errorText={getError('petsDetails', ['petCost', index])}
            maskQuestionData>
            <CostInput
              id={getIdForInput('petCost')}
              value={petDetails.petCost}
              placeholder={questions.petCost.placeholder}
              onCostChange={(value) => {
                updatePetDetails({
                  petCost: value,
                });
              }}
              onBlur={() => showValidation('petsDetails', ['petCost', index])}
              onFocus={trackFormTextFieldFocus(
                getAnalyticsDescriptionForInput('Pet cost')
              )}
              noCostButtonText={questions.petCost.noCostButtonText}
              onNoCostClicked={() =>
                trackRadioButtonClick(getAnalyticsDescriptionForInput('Pet cost'), '0')
              }
            />
          </QuestionField>
          <QuestionField
            question={questions.petSpayed}
            errorText={getError('petsDetails', ['petSpayed', index])}
            maskQuestionData>
            <BooleanRadioWithUnknownInput
              id={getIdForInput('petSpayed')}
              analyticsDescription={getAnalyticsDescriptionForInput('Is pet spayed')}
              value={petDetails.petSpayed}
              onChange={(value) => {
                updatePetDetails({
                  petSpayed: value,
                });
              }}
            />
          </QuestionField>
        </>
      )}
      {showRemainingCatQuestions && (
        <QuestionField
          question={questions.petChipped}
          errorText={getError('petsDetails', ['petChipped', index])}
          maskQuestionData>
          <BooleanRadioWithUnknownInput
            id={getIdForInput('petChipped')}
            analyticsDescription={getAnalyticsDescriptionForInput('Is pet chipped')}
            value={petDetails.petChipped}
            onChange={(value) => {
              updatePetDetails({
                petChipped: value,
              });
            }}
          />
        </QuestionField>
      )}
      {(showRemainingDogQuestions || showRemainingCatQuestions) && (
        <Grid alignLeft>
          <GridItem desktop={8} tabletLandscape={8}>
            <StyledFlexGrid alignLeft>
              {additionalPetsIcon && <SizedImage image={additionalPetsIcon} />}
              <StyledPanelHeading>{important_info_header.heading}</StyledPanelHeading>
            </StyledFlexGrid>
            <RichTextWithModal
              data-cy="important_information"
              html={important_information}
              onLinkClick={trackRichTextLinkClick(PageTitle.AboutYourPet)}
              pageTitle={PageTitle.AboutYourPet}
            />
          </GridItem>
        </Grid>
      )}
    </div>
  );
};

export default AboutYourPetForm;
