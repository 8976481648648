import { ButtonsPerRowByBreakpoint } from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { InputComponent } from '@rsa-digital/evo-shared-components/components/Form/inputTypes';
import React from 'react';
import { trackRadioButtonClick } from 'helpers/eventTracking';
import useBooleanAnswerText from 'helpers/useBooleanAnswerText';
import { StyledRadioInput } from './styles';

type BooleanRadioInputProps = {
  id: string;
  value: boolean | undefined;
  onChange: (newValue: boolean) => void;
  'aria-invalid'?: boolean;
  'aria-describedby'?: string;
  yesLabel?: string;
  noLabel?: string;
  analyticsDescription?: string;
  buttonsPerRow?: ButtonsPerRowByBreakpoint;
};

const yesValue = 'yes';
const noValue = 'no';

const valueToString = (value: boolean | undefined): string => {
  if (value === undefined) {
    return '';
  }
  return value ? yesValue : noValue;
};

const BooleanRadioInput: InputComponent<BooleanRadioInputProps> = ({
  id,
  value,
  onChange,
  'aria-invalid': ariaInvalid,
  'aria-describedby': ariaDescribedBy,
  yesLabel,
  noLabel,
  analyticsDescription,
  buttonsPerRow,
}) => {
  const { yes, no } = useBooleanAnswerText();

  return (
    <StyledRadioInput
      id={id}
      options={[
        { name: yesLabel ?? yes, value: yesValue },
        { name: noLabel ?? no, value: noValue },
      ]}
      value={valueToString(value)}
      onChange={(e) => {
        onChange(e.target.value === yesValue);
        trackRadioButtonClick(
          analyticsDescription || id,
          e.target.value === yesValue ? yesValue : noValue
        );
      }}
      aria-describedby={ariaDescribedBy}
      aria-invalid={ariaInvalid}
      buttonsPerRow={buttonsPerRow}
    />
  );
};

BooleanRadioInput.isFieldSet = true;

export default BooleanRadioInput;
